import { LoginCallback } from '@okta/okta-react'
import { Route, Routes } from 'react-router-dom'

// import OktaSignInWidget from '../auth/OktaSignInWidget'
import Applications from '../pages/Applications'
import { Layout } from './Layout'
import { NoMatch } from './NoMatch'
import { RequireAuth } from './RequireAuth'

export const Router: React.FC = () => (
  <Routes>
    {/* No RequireAuth wrapper for Login and LoginCallback routes */}
    <Route path='/login/callback' element={<LoginCallback />} />

    {/* Wrap protected routes with RequireAuth */}
    <Route element={<RequireAuth />}>
      <Route element={<Layout />}>
        <Route path='/' element={<Applications />} />
      </Route>
    </Route>

    {/* Catch-all route for unmatched paths */}
    <Route path='*' element={<NoMatch />} />
  </Routes>
)
