import { Box, Button, Typography } from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect, useState } from 'react'
import './Applications.scss'

import { CustomUserClaim } from '@okta/okta-auth-js'

import { Application } from '../Types/Application'
import { applications } from '../api/v1/ApplicationData'
import { ReactComponent as Bet365LogoBlack } from '../assets/Bet365LogoBlack.svg'
import { ReactComponent as Bet365Logo } from '../assets/Bet365LogoWhite.svg'
import { ReactComponent as EmployeePortalLogo } from '../assets/EmployeePortalLogo.svg'
import { ReactComponent as EmployeePortalLogoGreen } from '../assets/EmployeePortalLogoGreen.svg'
import { ReactComponent as LockIcon } from '../assets/IconFeatherLock.svg'

export const Applications: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth()
  const [userRoles, setUserRoles] = useState<CustomUserClaim[]>([])

  const handleLaunchApp = (app: Application) => {
    window.open(app.url, '_blank')
  }
  const handleLogout = async () => {
    await oktaAuth.signOut()
  }

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        if (info['roles']) {
          setUserRoles(info['roles'] as CustomUserClaim[])
        }
      })
    }
  }, [authState])

  return (
    <>
      <Box className='applications'>
        <div className='applications__employeeLogoContainer'>
          <EmployeePortalLogoGreen className='applications__employeeLogo' />
        </div>
        <div className='applications__LeftContainer'>
          <div className='applications__aside-gradient' />
          <div className='applications__aside'>
            <div className='applications__aside-content'>
              <div className='applications__aside-logoContainer'>
                <EmployeePortalLogo className='applications__aside-exployeePortalLogo' />
                <Typography
                  variant='h4'
                  component='h1'
                  className='applications__aside-title'
                >
                  MY{' '}
                  <Box
                    component='span'
                    className='applications__aside-title--highlight'
                  >
                    APPS
                  </Box>
                </Typography>
              </div>
              <Bet365Logo className='applications__aside-bet365logo' />
            </div>
          </div>
        </div>
        <Box className='applications__content'>
          <div className='applications__container'>
            {applications
              .filter((app) =>
                userRoles.some((roleClaim) => {
                  return roleClaim.toString().includes(app.grp)
                }),
              )
              .map((app) => (
                <div
                  className='applications__card'
                  key={app.id}
                  onClick={() => handleLaunchApp(app)}
                >
                  <img
                    src={app.img}
                    alt='App Icon'
                    className='applications__card-icon'
                  />
                  <div className='applications__card-content'>
                    <Typography className='applications__card-contentFont'>
                      {app.name}
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
          <Box className='applications__footer'>
            <Box className='applications__footer-gradientContainer'>
              <div className='applications__footer-gradient' />
            </Box>
            <div className='applications__footer-logos'>
              <Bet365LogoBlack className='applications__footer-logos-bet365' />
              <EmployeePortalLogoGreen className='applications__footer-logos-portal' />
            </div>
            <div className='applications__footer-logos-triangles' />
          </Box>
          <Box className='applications__logout'>
            <Button
              onClick={handleLogout}
              className='applications__logout-button'
            >
              <LockIcon /> <span>Logout</span>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default Applications
