import type { OktaAuthOptions } from '@okta/okta-auth-js'

const isLocalHost = window.location.origin.toLowerCase().includes('localhost')
const isDEV = window.location.origin.toLowerCase().includes('dev')
const isUAT = window.location.origin.toLowerCase().includes('uat')
const isPreProd = window.location.origin.toLowerCase().includes('preprod')

const CLIENT_ID =
  isLocalHost || isDEV || isUAT
    ? process.env['REACT_APP_DEV_UAT_CLIENT_ID']
    : process.env['REACT_APP_PREPROD_PROD_CLIENT_ID']
const ISSUER =
  isLocalHost || isDEV || isUAT
    ? process.env['REACT_APP_DEV_UAT_ISSUER']
    : process.env['REACT_APP_PREPROD_PROD_ISSUER']

const REDIRECT_URI = `${window.location.origin}/login/callback`

let postLogoutRedirectUri = 'https://portal.bss.hillsidenewmedia.com'
if (isDEV) {
  postLogoutRedirectUri = 'https://portal.bss.dev.hillsidenewmedia.com/'
} else if (isUAT) {
  postLogoutRedirectUri = 'https://portal.bss.uat.hillsidenewmedia.com/'
} else if (isPreProd) {
  postLogoutRedirectUri = 'https://portal.bss.preprod.hillsidenewmedia.com/'
}

let oidc: OktaAuthOptions = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  postLogoutRedirectUri: postLogoutRedirectUri,
}
const oktaConfig = () => {
  // This is for testing purposes only
  if (process.env.NODE_ENV === 'test') {
    oidc = {
      ...oidc,
      // eslint-disable-next-line @typescript-eslint/require-await
      transformAuthState: async (_authInstance, authState) => {
        authState.isAuthenticated = true
        return authState
      },
    }
    return oidc
  }
  return oidc
}

export default oktaConfig
