import { Application } from '../../Types/Application'
import PlannerLogo from '../../assets/PlannerAppTile.svg'

const isDEV = window.location.origin.toLowerCase().includes('dev')
const isUAT = window.location.origin.toLowerCase().includes('uat')
const isPreProd = window.location.origin.toLowerCase().includes('preprod')

let PlannerURL = 'https://planner365.bss.hillsidenewmedia.com/'
if (isDEV) {
  PlannerURL = 'https://planner365.bss.dev.hillsidenewmedia.com/'
} else if (isUAT) {
  PlannerURL = 'https://planner365.bss.uat.hillsidenewmedia.com/'
} else if (isPreProd) {
  PlannerURL = 'https://planner365.bss.preprod.hillsidenewmedia.com/'
}

export const applications: Application[] = [
  {
    id: 1,
    name: 'planner365',
    description: 'Application description...',
    url: PlannerURL,
    img: PlannerLogo,
    grp: 'App_Okta_Planner365',
  },
]
