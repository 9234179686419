import { Link } from 'react-router-dom'

export const NoMatch: React.FC = () => {
  return (
    <div data-testid='404'>
      <h2>It looks like you are lost...</h2>
      <p>
        <Link to='/'>Go to the home page</Link>
      </p>
    </div>
  )
}
