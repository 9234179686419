import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import oktaConfig from './oktaConfig'

interface AuthenticationProps {
  children: React.ReactNode
}

export const oktaAuth = new OktaAuth(oktaConfig())

const Authentication: React.FC<AuthenticationProps> = ({ children }) => {
  const navigate = useNavigate()

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  useEffect(
    () => () => {
      // return restoreOriginalUri to undefined on unmount
      oktaAuth.options.restoreOriginalUri = undefined
    },
    [],
  )

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  )
}

export default Authentication
